import React from 'react'
import '../../../components/styles/global.scss'
import Layout from '../../../components/common/Layout'
import SegmentedControl from '../../../components/common/SegmentedControl'

export default function ClinicalTrailsPV() {
    return (
        <Layout active='pipeline-polycythemia-vera'
        title='PharmaEssentia Medical Affairs | Clinical Trials in Polycythemia Vera'
        description='Learn about PharmaEssentia’s clinical trials in polycythemia vera and discover currently enrolling trials.'
        socialTitle='PharmaEssentia Medical Affairs | Clinical Trials in Polycythemia Vera'
        socialDescription='Learn about PharmaEssentia’s clinical trials in polycythemia vera and discover currently enrolling trials.'
        pathname='/pipeline/clinical-trials/polycythemia-vera'
        >
            <section>
                <div className='inner'>
                    <h1>Our Pipeline</h1>
                    <SegmentedControl buttons='our-pipeline' active='pipeline-polycythemia-vera'/>
                    <h3>Clinical Trials in Polycythemia Vera (PV)</h3>
                    <p>Our commitment to scientific excellence is the basis for our ongoing clinical research. We are deeply appreciative of the study sites, investigators, patients, caregivers, and clinicians who support our efforts through their participation. Learn more about our trial data and currently enrolling trials below.</p>
                    <div className='trials'>
                        <h5>Currently Enrolling Trials</h5>
                        <div className='col'>
                            <img className='logo' src='/svg/eclipse-pv-logo.svg' alt='Eclipse PV logo'/>
                            <p>A Phase 3b, randomized, open-label, parallel-group, multicenter study to evaluate the efficacy and safety of two dosing regimens of ropeginterferon alfa-2b-njft.</p>
                            <a 
                            className='off-site'
                            href='https://clinicaltrials.gov/ct2/show/NCT05481151'
                            target='_blank'
                            rel='noreferrer'>ClinicalTrials.gov: NCT05481151 <span className='triangle'></span></a>
                            <div className='row gapped'>
                                <div className='col'>
                                    <ul className='bullets'>
                                        <li className='no-list'>For more information about ECLIPSE PV:</li>
                                        <li><span>Call the study information center at <a className="text-link no-break" href="tel:+18009992449">1-800-999-2449</a></span></li>
                                        <li><span>Email the PharmaEssentia Medical Information team at <a className='text-link no-break' href="mailto:medinfo@pharmaessentia-us.com?subject=Request%20for%20more%20information:%20Exceed%20ET%20Clinical%20Trial">medinfo@pharmaessentia-us.com</a></span></li>
                                    </ul>
                                </div>
                                <div className='col-end width-max-content'>
                                    <span className='flex'>
                                        <a 
                                        target='_blank'
                                        rel='noreferrer'
                                        className='cta-button'
                                        href='http://www.eclipsepv.com/hcp'
                                        >visit <span>eclipsepv.com/hcp</span></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='trials'>
                        <h5>Completed Trials</h5>
                        <div className='col'>
                            <img className='logo sml' src='/svg/peginvera-logo.svg' alt='Peginvera logo'/>
                            <p>2010-2018</p>
                            <p>A Phase 1/2, prospective, multicenter, open-label, dose-escalation trial to determine the safety, efficacy, and tolerability of ropeginterferon alfa-2b-njft in patients with PV, and define the maximum tolerated dose.</p>
                            <span className='divider'></span>
                            <img className='logo sml' src='/svg/continuation-pv-logo.svg' alt='Continuation-PV logo'/>
                            <p>2014-2021</p>
                            <p>A Phase 3b, open-label extension trial in patients who completed PROUD-PV to assess the long-term efficacy of ropeginterferon alfa-2b-njft or standard first-line treatment (HU or other best available treatment*) in terms of disease response rate and changes in disease burden.</p>
                            <p className='sml row'>*<span>Includes peginterferon alfa-2a, acetylsalicylic acid, anagrelide, IFN alfa, ruxolitinib, IFN alfa-2a, both ruxolitinib and IFN alfa-2a, ropeginterferon alfa-2b-njft, or both ropeginterferon alfa-2b-njft and IFN alfa-2a.</span></p>
                            <span className='divider'></span>
                            <img className='logo sml' src='/svg/proud-pv-logo.svg' alt='Proud-PV logo'/>
                            <p>2013-2016</p>
                            <p>A Phase 3, randomized clinical trial conducted across 48 clinics in Europe to assess the noninferiority of ropeginterferon alfa-2b-njft versus hydroxyurea (HU) regarding disease response in the treatment of PV.</p>                            
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}